<script lang="ts" setup>
import type { FetchGenresQueryVariables } from '#gql/default'

interface Props {
  fetchKey: string
  fetchParams: FetchGenresQueryVariables
}

const localeRoute = useLocaleRoute()

defineProps<Props>()

defineOptions({
  name: 'GenreList',
})
</script>

<template>
  <GenresFetch :fetch-key="fetchKey" :fetch-params="fetchParams">
    <template #default="{ genres, paginatorInfo }">
      <Paginator :paginator-info="paginatorInfo">
        <div
          class="@lg:grid-cols-3 @xl:grid-cols-4 @2xl:grid-cols-5 grid grid-cols-2 gap-2"
        >
          <NuxtLink
            v-for="{ urlSlug, name, id } in genres"
            :key="`${urlSlug}-${id}`"
            :to="
              localeRoute({
                name: ROUTE_NAMES.GENRE_PAGE,
                params: {
                  genreSlug: urlSlug,
                },
              })
            "
            class="hover:underline"
          >
            {{ name }}
          </NuxtLink>
        </div>
      </Paginator>
    </template>
  </GenresFetch>
</template>
